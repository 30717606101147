<template>
    <div v-loading.fullscreen.lock="loading">
        <my-apply-search-bar-component
            @search="get_my_apply_index"
        ></my-apply-search-bar-component>
        <common-table-component
            button_name="查看"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @watch_row="watch_details"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_my_apply_index"
        ></common-page-component>
        <!-- 采购 -->
        <purchase-edit-component
            v-if="dialog_type===0"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :enterprise_department="enterprise_department"
            :purchase_details_data="dialog.details_data"
            print_auth="打印采购申请"
            :budget_count="dialog.budget_count"
            @exitPurchaseDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></purchase-edit-component>
        <!-- 招待 -->
        <entertain-edit-component
            v-if="dialog_type===1"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :enterprise_department="enterprise_department"
            :entertain_details_data="dialog.details_data"
            print_auth="打印招待申请"
            :budget_count="dialog.budget_count"
            @exitEntertainDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></entertain-edit-component>
        <!-- 差旅 -->
        <travel-edit-component
            v-if="dialog_type===6"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :enterprise_department="enterprise_department"
            :travel_details_data="dialog.details_data"
            print_auth="打印差旅申请"
            :budget_count="dialog.budget_count"
            @exitTravelDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></travel-edit-component>
        <!-- 合同 -->
        <contract-edit-component
            v-if="dialog_type===14"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :contract_details_data="dialog.details_data"
            print_auth="打印合同"
            :AllClient="fAllClient"
            :AllEnterprise="AllEnterprise"
            :AllUrgentState="AllUrgentState"
            :AllBusinessType="AllBusinessTypeContract"
            :AllContractType="AllContractType"
            :AllSubjectNum="AllSubjectNum"
            :AllSettlementMode="AllSettlementMode"
            :AllSettlementDayType="AllSettlementDayType"
            :AllSettlementCycle="AllSettlementCycle"
            @exitContractDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></contract-edit-component>
        <!-- 备用金 -->
        <petty-edit-component
            v-if="dialog_type===2"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :enterprise_department="enterprise_department"
            :petty_details_data="dialog.details_data"
            print_auth="打印备用金申请"
            :budget_count="dialog.budget_count"
            @exitPettyDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></petty-edit-component>
        <!-- 报销 -->
        <reimbursement-edit-component
            v-if="dialog_type===7"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :enterprise_department="enterprise_department"
            :reimbursement_details_data="dialog.details_data"
            print_auth="打印报销申请"
            :budget_count="dialog.budget_count"
            @exitReimbursementDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></reimbursement-edit-component>
        <!-- 休假 -->
        <vacation-edit-component
            v-if="dialog_type===8"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :vacation_details_data="dialog.details_data"
            print_auth="打印休假申请"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @exitDialog="dialogExit"
            @frefresh="dialog_frefresh"
        ></vacation-edit-component>
        <!-- 外出 -->
        <go-out-edit-component  
            v-if="dialog_type===11"          
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :go_out_details_data="dialog.details_data"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            print_auth="打印外出申请"
            @show_edit="show_edit"        
            @search="get_my_apply_index"
            @exitDialog="dialogExit"
            @frefresh="dialog_frefresh"           
        ></go-out-edit-component>
        <!-- 加班 -->
        <over-time-edit-component  
            v-if="dialog_type===12"          
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :over_time_details_data="dialog.details_data"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            print_auth="打印加班申请"
            @show_edit="show_edit"        
            @search="get_my_apply_index"
            @exitDialog="dialogExit"
            @frefresh="dialog_frefresh"           
        ></over-time-edit-component>
        <!-- 补签 -->
        <supplement-clock-in-edit-component  
            v-if="dialog_type===13"          
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :supplement_clock_in_details_data="dialog.details_data"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            print_auth="打印补签申请"
            @show_edit="show_edit"        
            @search="get_my_apply_index"
            @exitDialog="dialogExit"
            @frefresh="dialog_frefresh"           
        ></supplement-clock-in-edit-component>
        <!-- 入库 -->
        <entry-bill-edit-component
            v-if="dialog_type===3"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :entry_bill_details_data="dialog.details_data"
            :AllClient="fAllClient"
            :fAllClientStockRate="fAllClientStockRate"
            :fAllSatff="fAllSatff"
            :storeroom_in_enterprise="fstoreroom_in_enterprise"
            @exitEntryBillDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
            @fClientStockRateChange="get_client_stock_rate"
        ></entry-bill-edit-component>
        <!-- 出库 -->
        <detry-bill-edit-component
            v-if="dialog_type===4"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :detry_bill_details_data="dialog.details_data"
            :AllClient="fAllClient"
            :fAllSatff="fAllSatff"
            @exitDetryBillDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @refresh="dialog_frefresh"
            @frefresh="dialog_frefresh"
        ></detry-bill-edit-component>
        <!-- 移库 -->
        <mvtry-bill-edit-component
            v-if="dialog_type===5"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :mvtry_bill_details_data="dialog.details_data"
            :fAllSatff="fAllSatff"
            @exitMvtryBillDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @refresh="dialog_frefresh"
            @frefresh="dialog_frefresh"
        ></mvtry-bill-edit-component>
        <!-- 仓库账单 -->
        <reconciliation-edit-component
            v-if="dialog_type===9"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :sel_auth="dialog.sel_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :AllClient="fAllClient"
            :reconciliation_details_data="dialog.details_data"
            @exitDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></reconciliation-edit-component>
        <!-- 业务付款 -->
        <business-payment-edit-component
            v-if="dialog_type===10"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :pay_auth="dialog.pay_auth"
            :enterprise_department="enterprise_department"
            :business_payment_details_data="dialog.details_data"
            @exitDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></business-payment-edit-component>
        <!-- 合作企业 -->
        <cooperative-enterprise-edit-component
            v-if="dialog_type===15"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :cooperative_enterprise_details_data="dialog.details_data"
            :AllType="AllType"
            :AllAccountPeriodType="AllAccountPeriodType"
            :AllBusinessType="AllBusinessType"
            :AllStaff="fAllSatff"
            :AllBankCard="AllBankCard"
            @exitDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></cooperative-enterprise-edit-component>
        <!-- 中远订单 -->
        <cop-order-edit-component
            v-if="dialog_type===16"
            :id="dialog.id"
            :dialogFormVisible="dialog.dialogFormVisible"
            :CopOrder="dialog.details_data"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            @exitDialog="dialogExit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></cop-order-edit-component>
        <!-- 印章使用申请 -->
        <currency-edit-component
            v-if="dialog_type===17"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :enterprise_department="enterprise_department"
            :currency_details_data="dialog.details_data"
            print_auth="打印印章使用申请"
            @exitDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></currency-edit-component>
        <!-- 入职登记表申请 -->
        <staff-entry-edit-component
            v-if="dialog_type===18"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :AllEnterprise="AllEnterprise"
            :AllRank="AllRank"
            :staff_entry_details_data="dialog.details_data"
            print_auth="打印入职登记表"
            @exitDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></staff-entry-edit-component>
        <!-- 公务车使用申请 -->
        <car-use-edit-component
            v-if="dialog_type===19"
            :id="dialog.id"
            :show_type="dialog.edit_type"
            :dialogFormVisible="dialog.dialogFormVisible"
            :edit_auth="dialog.edit_auth"
            :del_auth="dialog.del_auth"
            :to_approval_auth="dialog.to_approval_auth"
            :sel_approval_auth="dialog.sel_approval_auth"
            :ApprovalAuth="dialog.ApprovalAuth"
            :withdraw_auth="dialog.withdraw_auth"
            :AllStaff="fAllSatff"
            :enterprise_department="enterprise_department"
            :car_use_details_data="dialog.details_data"
            print_auth="打印公务车使用申请"
            @exitDialog="dialogExit"
            @show_edit="show_edit"
            @search="get_my_apply_index"
            @frefresh="dialog_frefresh"
        ></car-use-edit-component>
    </div>
</template>

<script>
import { my_apply_request } from '@/network/user.js'
import { enterprise_department_request,enterprise_storeroom_request} from '@/network/enterprise.js'
import { client_stock_rate_list_request,client_list_request,staff_list_request,enterprise_list_request,bank_card_list_request } from '@/network/list.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'
import { budget_count_request } from '@/network/finance/BudgetUse.js'

import MyApplySearchBarComponent from '@/components/user/MyApply/MyApplySearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

import { purchase_details_request} from '@/network/finance/purchase.js'
import PurchaseEditComponent from '@/components/finance/purchase/PurchaseEditComponent'

import { entertain_details_request} from '@/network/finance/entertain.js'
import EntertainEditComponent from '@/components/finance/entertain/EntertainEditComponent'

import { travel_details_request} from '@/network/finance/travel.js'
import TravelEditComponent from '@/components/finance/travel/TravelEditComponent'

import { contract_details_request} from '@/network/finance/Contract.js'
import ContractEditComponent from '@/components/finance/Contract/ContractEditComponent'

import { petty_details_request} from '@/network/finance/petty.js'
import PettyEditComponent from '@/components/finance/petty/PettyEditComponent'

import { reimbursement_details_request} from '@/network/finance/reimbursement.js'
import ReimbursementEditComponent from '@/components/finance/reimbursement/ReimbursementEditComponent'

import { vacation_details_request} from '@/network/hrm/vacation.js'
import VacationEditComponent from '@/components/hrm/vacation/VacationEditComponent'

import { go_out_details_request} from '@/network/hrm/GoOut.js'
import GoOutEditComponent from '@/components/hrm/goout/GoOutEditComponent'

import { over_time_details_request} from '@/network/hrm/OverTime.js'
import OverTimeEditComponent from '@/components/hrm/overtime/OverTimeEditComponent'

import { supplement_clock_in_details_request} from '@/network/hrm/SupplementClockIn.js'
import SupplementClockInEditComponent from '@/components/hrm/SupplementClockIn/SupplementClockInEditComponent'

import { entry_bill_details_request } from '@/network/WareHouse/EntryBill.js'
import EntryBillEditComponent from '@/components/WareHouse/EntryBill/EntryBillEditComponent'

import { detry_bill_details_request } from '@/network/WareHouse/DetryBill.js'
import DetryBillEditComponent from '@/components/WareHouse/DetryBill/DetryBillEditComponent'

import { mvtry_bill_details_request } from '@/network/WareHouse/MvtryBill.js'
import MvtryBillEditComponent from '@/components/WareHouse/MvtryBill/MvtryBillEditComponent'

import { reconciliation_details_request } from '@/network/WareHouse/reconciliation.js'
import ReconciliationEditComponent from '@/components/WareHouse/Reconciliation/ReconciliationEditComponent'

import { business_payment_details_request } from '@/network/finance/BusinessPayment.js'
import BusinessPaymentEditComponent from '@/components/finance/BusinessPayment/BusinessPaymentEditComponent'

import { cooperative_enterprise_details_request } from '@/network/business/CooperativeEnterprise.js'
import CooperativeEnterpriseEditComponent from '@/components/business/CooperativeEnterprise/CooperativeEnterpriseEditComponent'

import { cop_order_details_request } from '@/network/cop/CopOrder.js'
import CopOrderEditComponent from '@/components/cop/CopOrder/CopOrderEditComponent'

import { currency_details_request } from '@/network/finance/currency.js'
import CurrencyEditComponent from '@/components/finance/currency/CurrencyEditComponent'

import { staff_entry_details_request} from '@/network/hrm/StaffEntry.js'
import StaffEntryEditComponent from '@/components/hrm/StaffEntry/StaffEntryEditComponent'

import { car_use_details_request} from '@/network/hrm/CarUse.js'
import CarUseEditComponent from '@/components/hrm/CarUse/CarUseEditComponent'

export default {
    name:'MyApplyView',
    data(){
        return {
            loading: false,
            cond: {
                my_state: '',
                examine_type: '',
                urgent_state: '',
                name: '',
                money: '',
                page: 1,
                limit: 10
            },
            ftable_data: [],
            ftotal: 0,
            fcol_arr: [
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '90px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },{
                    prop: 'urgent_state',
                    label: '紧急程度',
                    minWidth: '90px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '一般'
                            }
                        } else if(d===1) {
                            return {
                                type: 'warning',
                                filter_data: '较急'
                            }
                        } else if(d===2) {
                            return {
                                type: 'danger',
                                filter_data: '特急'
                            }
                        }
                    }
                },{
                    prop: 'examine_type',
                    label: '申请类型',
                    minWidth: '140px',
                    editdata: (row) => {
                        let examine_type_name = ''
                        this.$store.state.examine_type.forEach(e => {
                            if(row['examine_type'] === e.id) {
                                examine_type_name = e.name
                                return
                            }
                        });
                        return examine_type_name
                    }
                },{
                    prop: 'title',
                    label: '标题',
                    minWidth: '300px',
                    html:(row) => {
                        let arr = []
                        let res = ''
                        if(row['examine_type'] === 10) {    //业务付款
                            arr = row['title'].split("|")
                            res += '<div style="font-weight: bold;">业务付款</div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--danger el-tag--plain el-tag--mini">付款方</span><span> ' + arr[0] + '</span></div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--success el-tag--plain el-tag--mini">收款方</span><span> ' + arr[1] + '</span></div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--info el-tag--plain el-tag--mini">最迟日</span><span> ' + arr[2] + '</span></div>'
                            return res
                        } else if(row['examine_type'] === 14) { //合同
                            arr = row['title'].split("|")
                            res += '<div style="font-weight: bold;">' + arr[0] + '</div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--danger el-tag--plain el-tag--mini">甲方</span><span> ' + arr[1] + '</span></div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--success el-tag--plain el-tag--mini">乙方</span><span> ' + arr[2] + '</span></div>'
                            if(arr[3]) {
                                res += '<div style="text-align: left;"><span class="el-tag el-tag--plain el-tag--mini">丙方</span><span> ' + arr[3] + '</span></div>'
                            }
                            if(arr[4]) {
                                res += '<div style="text-align: left;"><span class="el-tag el-tag--warning el-tag--plain el-tag--mini">丁方</span><span> ' + arr[4] + '</span></div>'
                            }

                            return res
                        } else if(row['examine_type'] === 15) { //合作企业
                            arr = row['title'].split("|")
                            let title = ''
                            if(arr[4]*1 === 0) {
                                title = '客户'
                            } else if(arr[4]*1 === 1) {
                                title = '供应商'
                            } else if(arr[4]*1 === 2) {
                                title = '我方企业'
                            }

                            res += '<div style="font-weight: bold;">新增' + title + '</div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--plain el-tag--mini">我方</span><span> ' + arr[0] + '</span></div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--warning el-tag--plain el-tag--mini">合作</span><span> ' + arr[1] + '</span></div>'
                            res += '<div style="text-align: left;">'
                            if(arr[2]*1 === 0) {
                                res += '<span class="el-tag el-tag--danger el-tag--mini">禁用合作</span>'
                            } else if(arr[2]*1 === 1) {
                                res += '<span class="el-tag el-tag--warning el-tag--mini">临时合作</span>'
                            } else if(arr[2]*1 === 2) {
                                res += '<span class="el-tag el-tag--mini">长期合作</span>'
                            }
                            res += ' '
                            if(arr[3]*1 === 0) {
                                res += '<span class="el-tag el-tag--danger el-tag--mini">不签合同</span>'
                            } else if(arr[3]*1 === 1) {
                                res += '<span class="el-tag el-tag--success el-tag--mini">签订合同</span>'
                            }
                            res += '</div>'

                            return res
                        } else if(row['examine_type'] === 18) { //入职登记表
                            arr = row['title'].split("|")
                            res += '<div style="font-weight: bold;">入职申请</div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--plain el-tag--mini">姓名</span><span> ' + arr[0] + '</span></div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--success el-tag--plain el-tag--mini">职位</span><span> ' + arr[1] + '</span></div>'
                            res += '<div style="text-align: left;"><span class="el-tag el-tag--warning el-tag--plain el-tag--mini">企业</span><span> ' + arr[2] + '</span></div>'
                            res += '<div style="text-align: left;">'

                            return res
                        } else {
                            return '<div style="font-weight: bold;">' + row['title'] + '</div>'
                        }
                    }
                },{
                    prop: 'money',
                    label: '金额',
                    minWidth: '150px',
                    html:(row) => {
                        const no = [3,4,5,8,9,11,12,13,14,15,18]
                        if(no.indexOf(row['examine_type']) > -1) {
                            return ''
                        } else if(row['examine_type'] === 10) { //业务付款
                            const arra = row['money'].split("：")
                            if(arra[0] === 'RMB') {
                                return '<div style="text-align: right;"><span>' + thousandBitSeparator(fomatFloat(arra[1])) + ' </span>' + '<span class="el-tag el-tag--plain el-tag--small">' + arra[0] + '</span></div>'
                            } else {
                                return '<div style="text-align: right;"><span>' + thousandBitSeparator(fomatFloat(arra[1])) + ' </span>' + '<span class="el-tag el-tag--warning el-tag--plain el-tag--small">' + arra[0] + '</span></div>'
                            }
                        } else if(row['examine_type'] === 16) { //电商
                            const arr = row['money'].split("|")
                            return '<div style="text-align: right;"><span>' + thousandBitSeparator(fomatFloat(arr[0])) +' </span><span class="el-tag el-tag--plain el-tag--small">RMB</span></div>' + '<div style="text-align: right;"><span>' + thousandBitSeparator(fomatFloat(arr[1])) +' </span><span class="el-tag el-tag--warning el-tag--plain el-tag--small">USD</span></div>'
                        } else {
                            return '<div style="text-align: right;"><span>' + thousandBitSeparator(fomatFloat(row['money'])) + ' </span><span class="el-tag el-tag--plain el-tag--small">RMB</span></div>'
                        }
                    }
                },{
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '120px',
                },{
                    prop: 'name',
                    label: '单号',
                    minWidth: '120px'
                },{
                    prop: 'create_time',
                    label: '创建日期',
                    minWidth: '100px'
                }
            ],
            dialog_type: '',
            dialog: {
                id: 0,
                edit_type: 0,
                dialogFormVisible: false,
                details_data: {},
                edit_auth: false,
                del_auth: false,
                sel_auth: false,
                to_approval_auth: false,
                sel_approval_auth: false,
                ApprovalAuth: false,
                withdraw_auth: false,
                pay_auth: false,
                budget_count: {
                    all_quota: 0,
                    use_quota: 0,
                    per_quota: 0,
                    entertain_quota: 0,
                    travel_quota: 0,
                    other_quota: 0
                }
            },
            enterprise_department: [],
            fAllClient: [],
            fAllClientStockRate: [],
            fAllSatff: [],
            fstoreroom_in_enterprise: [],
            AllEnterprise: [],
            AllUrgentState: ['一般','紧急','特急'],
            AllBusinessTypeContract: ['服务','装卸','仓库租赁','仓储保管','公路运输','航空运输','报关报检','多式联运','码头装卸(理货)','水路运输','铁路运输'],
            AllContractType: ['采购','销售'],
            AllSubjectNum: ['双方协议','三方协议','四方协议'],
            AllSettlementMode: ['收到发票后','货物签收后','报关后','货物到港后','驳船开后','海船开后','货物到港前','次月','订舱后','货物出运前','本月','合同生效后'],
            AllSettlementDayType: ['自然日','工作日'],
            AllSettlementCycle: ['付款买单','票结','月结','预付款','年结'],
            AllType: ['客户','供应商','我方企业'],
            AllAccountPeriodType: ['付款买单','票结','月结','季结','半年结','年结','其它'],
            AllBusinessType: ['海运进口','海运出口','空运进口','空运出口','铁路进口','铁路出口','公路运输','多式联运','仓储装卸','理货报关','服务'],
            AllBankCard: [],
            AllRank: ['经理级别以下','经理及以上级别']
        }
    },
    computed:{},
    methods:{
        get_my_apply_index(param={}) {
            this.loading = true
            this.cond.my_state = param.my_state ?? this.cond.my_state
            this.cond.examine_type = param.examine_type ?? this.cond.examine_type
            this.cond.urgent_state = param.urgent_state ?? this.cond.urgent_state
            this.cond.name = param.name ?? this.cond.name
            this.cond.money = param.money ?? this.cond.money
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            my_apply_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        watch_details(row) {
            this.dialog_type = row.examine_type
            this.dialog.id = row.id
            this.dialog.dialogFormVisible = true
            this.dialog_frefresh()
        },
        dialogExit() {
            this.dialog.dialogFormVisible = false
            this.dialog.id = 0
            this.dialog.edit_type = 0,
            this.dialog.budget_count = {
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        },
        show_edit() {
            this.dialog.edit_type = 2
            this.dialog.budget_count = {
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        },
        dialog_frefresh() {
            this.loading = true
            if(this.dialog_type === 0) {    //采购
                purchase_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.dialog.details_data.department_id = [s.result.enterprise_id,s.result.department_id]
                            this.is_auth(s.result)
                            //获取预算
                            const department_id = this.dialog.details_data.department_id[1]
                            const year = this.dialog.details_data.apply_date ? this.dialog.details_data.apply_date .substr(0,4) : 0
                            this.get_budget_count(department_id ,year)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 1) { //招待
                entertain_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.dialog.details_data.department_id = [s.result.enterprise_id,s.result.department_id]
                            this.is_auth(s.result)
                            //获取预算
                            const department_id = this.dialog.details_data.department_id[1]
                            const year = this.dialog.details_data.apply_date ? this.dialog.details_data.apply_date .substr(0,4) : 0
                            this.get_budget_count(department_id ,year)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 6) { //差旅
                travel_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.dialog.details_data.department_id = [s.result.enterprise_id,s.result.department_id]
                            this.is_auth(s.result)
                            //获取预算
                            const department_id = this.dialog.details_data.department_id[1]
                            const year = this.dialog.details_data.start_time ? this.dialog.details_data.start_time .substr(0,4) : 0
                            this.get_budget_count(department_id ,year)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 14) { //合同
                contract_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }else if(this.dialog_type === 2) { //备用金
                petty_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.dialog.details_data.department_id = [s.result.enterprise_id,s.result.department_id]
                            this.is_auth(s.result)
                            //获取预算
                            const department_id = this.dialog.details_data.department_id[1]
                            const year = this.dialog.details_data.apply_date ? this.dialog.details_data.apply_date .substr(0,4) : 0
                            this.get_budget_count(department_id ,year)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 7) { //报销
                reimbursement_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.dialog.details_data.department_id = [s.result.enterprise_id,s.result.department_id]
                            this.is_auth(s.result)
                            //获取预算
                            const department_id = this.dialog.details_data.department_id[1]
                            const year = this.dialog.details_data.apply_date ? this.dialog.details_data.apply_date .substr(0,4) : 0
                            this.get_budget_count(department_id ,year)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 8) { //休假
                vacation_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 11) { //外出
                go_out_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 12) { //加班
                over_time_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 13) { //补签
                supplement_clock_in_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 3) { //入库
                entry_bill_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                            client_stock_rate_list_request({client_id: s.result.client_id})
                                .then((s) => {
                                    this.fAllClientStockRate = s.result
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 4) { //出库
                detry_bill_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 5) { //移库
                mvtry_bill_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 9) { //仓库账单
                reconciliation_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 10) { //业务付款
                business_payment_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 15) { //合作企业
                cooperative_enterprise_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 16) { //中远电商订单
                cop_order_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 17) { //印章使用申请
                currency_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 18) { //入职登记表申请
                staff_entry_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if(this.dialog_type === 19) { //公务车使用申请
                car_use_details_request(this.dialog.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.dialog.details_data = s.result
                            this.is_auth(s.result)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }

            this.loading = false
        },
        get_enterprise_department() {
            this.loading = true
            enterprise_department_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.enterprise_department = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_client() {
            client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_client_stock_rate(e) {
            client_stock_rate_list_request({client_id: e})
                .then((s) => {
                    this.fAllClientStockRate = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllSatff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_enterprise() {
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_bank_card() {
            bank_card_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllBankCard = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_enterprise_storeroom() {
            this.login_loading = true
            enterprise_storeroom_request()
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.fstoreroom_in_enterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        get_budget_count(department_id,year) {
            budget_count_request({ department_id:department_id, year:year })
                .then((s) => {
                    this.dialog.budget_count = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        is_auth(res) {
            if(res.examine_type === 0) {  //采购
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交采购申请')
                        this.dialog.edit_auth = this.$_has('修改采购申请')
                        this.dialog.del_auth = this.$_has('删除采购申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回采购申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批采购申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看采购申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看采购申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交采购申请')
                        this.dialog.edit_auth = this.$_has('修改采购申请')
                        this.dialog.del_auth = this.$_has('删除采购申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看采购申请审批记录')
                }
            } else if(res.examine_type === 1) {   //招待
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交招待申请')
                        this.dialog.edit_auth = this.$_has('修改招待申请')
                        this.dialog.del_auth = this.$_has('删除招待申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回招待申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批招待申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看招待申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看招待申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交招待申请')
                        this.dialog.edit_auth = this.$_has('修改招待申请')
                        this.dialog.del_auth = this.$_has('删除招待申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看招待申请审批记录')
                }
            } else if(res.examine_type === 6) { //差旅
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交差旅申请')
                        this.dialog.edit_auth = this.$_has('修改差旅申请')
                        this.dialog.del_auth = this.$_has('删除差旅申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回差旅申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批差旅申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看差旅申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看差旅申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交差旅申请')
                        this.dialog.edit_auth = this.$_has('修改差旅申请')
                        this.dialog.del_auth = this.$_has('删除差旅申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看差旅申请审批记录')
                }
            } else if(res.examine_type === 14) { //合同
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交合同')
                        this.dialog.edit_auth = this.$_has('修改合同')
                        this.dialog.del_auth = this.$_has('删除合同')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回合同')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批合同')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看合同审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看合同审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交合同')
                        this.dialog.edit_auth = this.$_has('修改合同')
                        this.dialog.del_auth = this.$_has('删除合同')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看合同审批记录')
                }
            }else if(res.examine_type === 2) { //备用金
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.pay_auth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交备用金申请')
                        this.dialog.edit_auth = this.$_has('修改备用金申请')
                        this.dialog.del_auth = this.$_has('删除备用金申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回备用金申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批备用金申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看备用金申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看备用金申请审批记录')
                    this.dialog.pay_auth = this.$_has('备用金汇款')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交备用金申请')
                        this.dialog.edit_auth = this.$_has('修改备用金申请')
                        this.dialog.del_auth = this.$_has('删除备用金申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看备用金申请审批记录')
                }
            } else if(res.examine_type === 7) { //报销
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.pay_auth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交报销申请')
                        this.dialog.edit_auth = this.$_has('修改报销申请')
                        this.dialog.del_auth = this.$_has('删除报销申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回报销申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批报销申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看报销申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看报销申请审批记录')
                    this.dialog.pay_auth = this.$_has('报销单汇款')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交报销申请')
                        this.dialog.edit_auth = this.$_has('修改报销申请')
                        this.dialog.del_auth = this.$_has('删除报销申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看报销申请审批记录')
                }
            } else if(res.examine_type === 8) { //休假
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.to_approval_auth = this.$_has('提交休假申请')
                        this.dialog.edit_auth = this.$_has('修改休假申请')
                        this.dialog.del_auth = this.$_has('删除休假申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.withdraw_auth = this.$_has('撤回休假申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批休假申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看休假申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看休假申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.to_approval_auth = this.$_has('提交休假申请')
                        this.dialog.edit_auth = this.$_has('修改休假申请')
                        this.dialog.del_auth = this.$_has('删除休假申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看休假申请审批记录')
                }
            } 
            else if(res.examine_type === 11) { //外出
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.to_approval_auth = this.$_has('提交外出申请')
                        this.dialog.edit_auth = this.$_has('修改外出申请')
                        this.dialog.del_auth = this.$_has('删除外出申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.withdraw_auth = this.$_has('撤回外出申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批外出申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看外出申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看外出申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.to_approval_auth = this.$_has('提交外出申请')
                        this.dialog.edit_auth = this.$_has('修改外出申请')
                        this.dialog.del_auth = this.$_has('删除外出申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看外出申请审批记录')
                }
            }else if(res.examine_type === 12) { //加班
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.to_approval_auth = this.$_has('提交加班申请')
                        this.dialog.edit_auth = this.$_has('修改加班申请')
                        this.dialog.del_auth = this.$_has('删除加班申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.withdraw_auth = this.$_has('撤回加班申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批加班申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看加班申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看加班申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.to_approval_auth = this.$_has('提交加班申请')
                        this.dialog.edit_auth = this.$_has('修改加班申请')
                        this.dialog.del_auth = this.$_has('删除加班申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看加班申请审批记录')
                }
            }else if(res.examine_type === 13) { //补签
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.to_approval_auth = this.$_has('提交补签申请')
                        this.dialog.edit_auth = this.$_has('修改补签申请')
                        this.dialog.del_auth = this.$_has('删除补签申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.withdraw_auth = this.$_has('撤回补签申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批补签申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看补签申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看补签申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if(res.create_staff === this.$store.state.user.id) {
                        this.dialog.to_approval_auth = this.$_has('提交补签申请')
                        this.dialog.edit_auth = this.$_has('修改补签申请')
                        this.dialog.del_auth = this.$_has('删除补签申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看补签申请审批记录')
                }
            }else if(res.examine_type === 3) { //入库单
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交入库单')
                        this.dialog.edit_auth = this.$_has('修改入库单')
                        this.dialog.del_auth = this.$_has('删除入库单')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回入库单')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批入库单')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看入库单审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看入库单审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交入库单')
                        this.dialog.edit_auth = this.$_has('修改入库单')
                        this.dialog.del_auth = this.$_has('删除入库单')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看入库单审批记录')
                }
            } else if(res.examine_type === 4) { //出库单
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交出库单')
                        this.dialog.edit_auth = this.$_has('修改出库单')
                        this.dialog.del_auth = this.$_has('删除出库单')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回出库单')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批出库单')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看出库单审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看出库单审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交出库单')
                        this.dialog.edit_auth = this.$_has('修改出库单')
                        this.dialog.del_auth = this.$_has('删除出库单')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看出库单审批记录')
                }
            } else if(res.examine_type === 5) { //移库单
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交移库单')
                        this.dialog.edit_auth = this.$_has('修改移库单')
                        this.dialog.del_auth = this.$_has('删除移库单')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回移库单')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批移库单')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看移库单审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看移库单审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交移库单')
                        this.dialog.edit_auth = this.$_has('修改移库单')
                        this.dialog.del_auth = this.$_has('删除移库单')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看移库单审批记录')
                }
            } else if(res.examine_type === 9) { //仓储账单
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交仓储账单')
                        this.dialog.edit_auth = this.$_has('修改仓储账单')
                        this.dialog.del_auth = this.$_has('删除仓储账单')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回仓储账单')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批仓储账单')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看仓储账单审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看仓储账单审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交仓储账单')
                        this.dialog.edit_auth = this.$_has('修改仓储账单')
                        this.dialog.del_auth = this.$_has('删除仓储账单')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看仓储账单审批记录')
                }
            } else if(res.examine_type === 10) {    //业务付款
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.pay_auth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交业务付款申请')
                        this.dialog.edit_auth = this.$_has('修改业务付款申请')
                        this.dialog.del_auth = this.$_has('删除业务付款申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回业务付款申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批业务付款申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看业务付款申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看业务付款申请审批记录')
                    this.dialog.pay_auth = this.$_has('业务付款单汇款')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交业务付款申请')
                        this.dialog.edit_auth = this.$_has('修改业务付款申请')
                        this.dialog.del_auth = this.$_has('删除业务付款申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看业务付款申请审批记录')
                }
            } else if(res.examine_type === 15) {    //合作企业
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交合作企业申请')
                        this.dialog.edit_auth = this.$_has('修改合作企业申请')
                        this.dialog.del_auth = this.$_has('删除合作企业申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回合作企业申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批合作企业申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看合作企业申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看合作企业申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交合作企业申请')
                        this.dialog.edit_auth = this.$_has('修改合作企业申请')
                        this.dialog.del_auth = this.$_has('删除合作企业申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看合作企业申请审批记录')
                }
            } else if(res.examine_type === 16) {    //中远电商订单
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交中远订单申请')
                        this.dialog.del_auth = this.$_has('删除中远订单申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回中远订单申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批中远订单申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看中远订单申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看中远订单申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交中远订单申请')
                        this.dialog.del_auth = this.$_has('删除中远订单申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看中远订单申请审批记录')
                }
            } else if(res.examine_type === 17) {  //印章使用申请
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交印章使用申请')
                        this.dialog.edit_auth = this.$_has('修改印章使用申请')
                        this.dialog.del_auth = this.$_has('删除印章使用申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回印章使用申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批印章使用申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看印章使用申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看印章使用申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交印章使用申请')
                        this.dialog.edit_auth = this.$_has('修改印章使用申请')
                        this.dialog.del_auth = this.$_has('删除印章使用申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看印章使用申请审批记录')
                }
            } else if(res.examine_type === 18) {  //入职登记表申请
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交入职登记表')
                        this.dialog.edit_auth = this.$_has('修改入职登记表')
                        this.dialog.del_auth = this.$_has('删除入职登记表')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回入职登记表')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批入职登记表')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看入职登记表审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看入职登记表审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交入职登记表')
                        this.dialog.edit_auth = this.$_has('修改入职登记表')
                        this.dialog.del_auth = this.$_has('删除入职登记表')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看入职登记表审批记录')
                }
            } else if(res.examine_type === 19) {  //公务车使用申请
                this.dialog.edit_auth = false
                this.dialog.del_auth = false
                this.dialog.to_approval_auth = false
                this.dialog.sel_approval_auth = false
                this.dialog.ApprovalAuth = false
                this.dialog.withdraw_auth = false

                if(res.state === 0) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交公务车使用申请')
                        this.dialog.edit_auth = this.$_has('修改公务车使用申请')
                        this.dialog.del_auth = this.$_has('删除公务车使用申请')
                    }
                } else if(res.state === 1) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.withdraw_auth = this.$_has('撤回公务车使用申请')
                    }
                    //判断当前审批人是否为自己
                    if(res.current_examine_staff_id === this.$store.state.user.id) {
                        this.dialog.ApprovalAuth = this.$_has('审批公务车使用申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看公务车使用申请审批记录')
                } else if(res.state === 2) {
                    this.dialog.sel_approval_auth = this.$_has('查看公务车使用申请审批记录')
                } else if(res.state === 3) {
                    //判断创建人或申请人是否为自己
                    if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                        this.dialog.to_approval_auth = this.$_has('提交公务车使用申请')
                        this.dialog.edit_auth = this.$_has('修改公务车使用申请')
                        this.dialog.del_auth = this.$_has('删除公务车使用申请')
                    }
                    this.dialog.sel_approval_auth = this.$_has('查看公务车使用申请审批记录')
                }
            }
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_my_apply_index()
        this.get_enterprise_department()
        this.get_all_client()
        this.get_all_staff()
        this.get_all_enterprise()
        this.get_all_bank_card()
        this.get_enterprise_storeroom()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        MyApplySearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        PurchaseEditComponent,
        EntertainEditComponent,
        TravelEditComponent,
        ContractEditComponent,
        PettyEditComponent,
        ReimbursementEditComponent,
        EntryBillEditComponent,
        DetryBillEditComponent,
        MvtryBillEditComponent,
        ReconciliationEditComponent,
        BusinessPaymentEditComponent,
        VacationEditComponent,
        GoOutEditComponent,
        OverTimeEditComponent,
        SupplementClockInEditComponent,
        CooperativeEnterpriseEditComponent,
        CopOrderEditComponent,
        CurrencyEditComponent,
        StaffEntryEditComponent,
        CarUseEditComponent
    },
    watch:{}
}
</script>

<style>
</style>